
<template>
  <div class="main-content">


    <loading :active.sync="isLoading"
             :is-full-page="fullPage"></loading>

    <div class="row">
      <div class="col-md-3">
        <div class="input-group mb-3">

          <input type="text" v-model="keyword" class="form-control" placeholder="Search recipient" aria-label="" aria-describedby="basic-addon1">

          <div class="input-group-append">
            <button @click="searchInstitutionalVoucherRecipients" class="btn btn-primary" type="button">Search</button>
          </div>
        </div>
      </div>
      <div class="col-md-9">
      </div>
    </div>

    <br/>

    <b-table striped hover :items="recipients" :fields="columns">
      <template v-slot:cell(amount)="data">
        <span>KES. {{ data.value | number('0,0') }}</span>
      </template>
      <template v-slot:cell(balance)="data">
        <span>KES. {{ data.value | number('0,0') }}</span>
      </template>
      <template v-slot:cell(giftStatus)="data">
        <span  class="badge badge-success"  v-if="data.value == 'ACTIVE'">{{ data.value }}</span>
        <span class="badge badge-warning"  v-else-if="data.value == 'PENDING' || data.value == 'PARTIALLY_DEPLETED'" >{{ data.value.replace("-", " ") }}</span>
        <span class="badge badge-danger"  v-else-if="data.value == 'EXPIRED' || data.value == 'DEPLETED'" >{{ data.value }}</span>
      </template>

      <template v-slot:cell(options)="data">
        <!-- `data.value` is the value after formatted by the Formatter -->
        <b-dropdown text="Action">
          <b-dropdown-item  @click="openViewRedemptionsModal(data.item.redemptions)"> View Redemptions </b-dropdown-item>
          <b-dropdown-item  @click="openVoucherExpiryModalModal(data.item, 'Institution')"> Update Voucher Expiry </b-dropdown-item>
        </b-dropdown>
      </template>

    </b-table>

    <div class="row">
      <div class="col-md-12">
        <nav aria-label="Page navigation float-right" style="margin-top: 20px;">
          <ul class="pagination justify-content-center">
            <li v-if="pagination.current_page > 1" class="page-item"><a @click.prevent="changePage(pagination.current_page - 1)" class="page-link" href="#">Previous</a></li>
            <li v-for="page in pagesNumber"
                v-bind:class="[ page == isActived ? 'active' : '']" class="page-item"><a @click.prevent="changePage(page - 1)" class="page-link" href="#">{{ page }}</a></li>
            <li v-if="pagination.current_page < pagination.last_page"  class="page-item"><a  @click.prevent="changePage(pagination.current_page + 1)"class="page-link" href="#">Next</a></li>
          </ul>
        </nav>
      </div>
    </div>

    <b-modal size="lg" id="view-redemptions" title="Redemptions" @cancel="true"   centered>
      <table class="table table-bordered">
        <tr>
          <th>Date</th>
          <th>Amount</th>
          <th>Outlet</th>
          <th>Cashier</th>
          <th>Netsuite Status</th>
        </tr>
        <tr v-for="redemption in redemptions">
          <td >{{redemption.created}}</td>
          <td >{{redemption.amount}}</td>
          <td >
            <span v-if="redemption.outlet">{{redemption.outlet.name}}</span>
          </td>
          <td >
            <span v-if="redemption.redeemedBy">{{redemption.redeemedBy.name}} </span>
          </td>
          <td >{{redemption.netsuiteVoucherPost}}</td>
        </tr>
      </table>
    </b-modal>
    <b-modal id="update-voucher-expiry" title="Update Voucher Expiry" @cancel="true" @ok="submitVoucherExpiry"   centered>
      <form>
        <div class="form-group">
          <label>Expiry Date:</label> <br/>
          <date-picker   :lang="lang" v-model="voucherExpiry.expiryDate" valueType="format" :first-day-of-week="1"></date-picker>
        </div>
      </form>
    </b-modal>


  </div>
</template>
<script>

  import Loading from 'vue-loading-overlay';
  // Import stylesheet
  import 'vue-loading-overlay/dist/vue-loading.css'
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';

  import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      lang: {
        days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        pickers: ['next 7 days', 'next 30 days', 'previous 7 days', 'previous 30 days'],
        placeholder: {
          date: 'Select Date',
          dateRange: 'Select Date Range'
        }
      },
      recipients:[],
      roles:[],
      columns:["recipientName","recipientEmail","recipientPhoneNUmber","voucherCode","amount","balance",{
        key: 'giftStatus',
        label: 'Status',
        sortable: false
      },"expiryDate","options"],
      redemptions:[],
      isLoading: false,
      fullPage: true,
      keyword:"",
      pagination: {
        total: 0,
        per_page: 2,
        from: 1,
        to: 0,
        current_page: 0
      },
      offset: 4,
      voucherExpiry:{
        voucherId:"",
        expiryDate:"",
        voucherType:""
      },
    };
  },
  computed: {
    isActived: function () {
      return this.pagination.current_page + 1;
    },
    pagesNumber: function () {
      if (!this.pagination.to) {
        return [];
      }
      var from = this.pagination.current_page - this.offset;
      if (from < 1) {
        from = 1;
      }
      var to = from + (this.offset * 2);
      if (to >= this.pagination.last_page) {
        to = this.pagination.last_page;
      }
      var pagesArray = [];
      while (from <= to) {
        pagesArray.push(from);
        from++;
      }
      return pagesArray;
    }
  },
  components: {
    Loading,
    DatePicker
  },
  mounted (){
    this.getInstitutionVoucherRecipientsData();
  },

  methods: {
    ...mapActions(["getInstitutionVoucherRecipients","searchVoucherRecipient", "updateVoucherExpiryDate"]),

    openVoucherExpiryModalModal(voucher, voucherType) {
      this.voucherExpiry.voucherId = voucher.id;
      this.voucherExpiry.voucherType = voucherType;
      this.$bvModal.show("update-voucher-expiry")
    },
    submitVoucherExpiry() {
      this.isLoading = true;

      let self = this;

      this.updateVoucherExpiryDate( this.voucherExpiry).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("Voucher expiry updated", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        self.getInstitutionVoucherRecipientsData();

      })
          .catch(error => {

            self.isLoading = false;

            self.$bvToast.toast(error.message, {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false
            });

          })
    },

    openViewRedemptionsModal(redemptions) {
      this.redemptions = redemptions;
      this.$bvModal.show("view-redemptions")
    },
    changePage: function (page) {
      this.pagination.current_page = page;
      this.getInstitutionVoucherRecipientsData();
    },
    searchInstitutionalVoucherRecipients(){

      if(this.keyword) {
        let self = this;

        self.isLoading = true;

        this.searchVoucherRecipient({keyword: this.keyword, voucherId: this.$route.params.id})
            .then(function (recipients) {

              self.isLoading = false;

              self.recipients = recipients;
            })
            .catch(function (error) {

              self.isLoading = false;

            })
      }
    },
    getInstitutionVoucherRecipientsData(){
      let self = this;
      this.getInstitutionVoucherRecipients({page: this.pagination.current_page, voucherId: this.$route.params.id})
        .then(function (recipients){

          self.recipients = recipients.content;
          self.pagination.current_page = recipients.number;
          self.pagination.total = recipients.totalElements;
          self.pagination.per_page = recipients.numberOfElements;
          self.pagination.from = recipients.pageable.offset + 1 ;
          self.pagination.to = recipients.pageable.pageSize;
          self.pagination.last_page = recipients.totalPages;

        })
        .catch(function (error) {

          if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/login");
            }

          }
        })
    }
  }
};
</script>
